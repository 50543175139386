import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

import ShapePost from "../components/shapes/ShapePost"
import LazyLoad from "react-lazyload"

const Post = ({ pageContext, location }) => {
  const { title, content, date, author, excerpt, image } = pageContext.data

  const prevPost = pageContext.prev ? pageContext.prev.node : null
  const nextPost = pageContext.next ? pageContext.next.node : null

  return (
    <Layout>
      <SEO title={title} />

      <main className="blog_single">
        <ShapePost />

        <div className="container-fluid narrow">
          <div className="top_info">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Strona głowna</Link>
              </li>
              <li>/</li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
            </ul>

            <div className="page_header_wrapper">
              <h1
                className="page_header"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>

            <ul className="info">
              <li>
                {date
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join(".")}
              </li>
              <li>/</li>
              <li>{author}</li>
            </ul>
          </div>

          <div className="blog_single_content">
            <div className="row">
              <div className="col-12 col-lg-5">
                <div className="thumb_wrapper">
                  <LazyLoad once height={325}>
                    <div
                      className="thumb"
                      style={{
                        backgroundImage: `url(${
                          image ? image.source_url : ""
                        })`,
                      }}
                    ></div>
                  </LazyLoad>
                </div>
              </div>
              <div className="col-12 col-lg-7 blog_single_content__first_paragraph">
                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>

        <div className="container-fluid">
          <div className="page_navigation">
            <div className="row no-gutters">
              {pageContext.prev ? (
                <div className="col" key={prevPost.id}>
                  <div className="inner">
                    <p dangerouslySetInnerHTML={{ __html: prevPost.title }} />
                    <Link to={prevPost.slug} className="btn_custom">
                      &#60;Poprzedni wpis/&#62;
                    </Link>
                  </div>
                </div>
              ) : (
                " "
              )}
              {pageContext.next ? (
                <div className="col" key={nextPost.id}>
                  <div className="inner">
                    <p dangerouslySetInnerHTML={{ __html: nextPost.title }} />
                    <Link to={nextPost.slug} className="btn_custom">
                      &#60;Następny wpis/&#62;
                    </Link>
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Post
